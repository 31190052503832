




import { Component, Vue } from "vue-property-decorator";

import MenuPortineria from "@/components/menus/portineria/MenuPortineria.vue";

@Component({
  components: {
    MenuPortineria,
  },
})
export default class PortineriaMenuView extends Vue {

}
