




import { Component, Vue } from "vue-property-decorator";

import OperatnBaseMenu, { MenuItem } from '@/components/gears/bases/OperatnBaseMenu.vue';

@Component({
  components: {
    OperatnBaseMenu
  }
})
export default class MenuPortineria extends Vue {
  /* DATA */

  private items: MenuItem[] = [
    {
      icon: "mdi-account",
      text: "Ospiti",
      path: "/portineria/ospiti",
    },
    {
      icon: "mdi-door-sliding",
      text: "Portinerie",
      path: "/portineria/portinerie",
    },
    {
      icon: "mdi-table-large",
      text: "Tabellone",
      path: "/portineria/tabellone",
    },
    {
      icon: "mdi-bed",
      text: "Ricerca Stanze",
      path: "/portineria/ricerca-stanze",
    },
    {
      icon: "mdi-bed",
      text: "Controlli",
      path: "/portineria/controlli",
    },
  ];
}
