




import { Component, Vue } from "vue-property-decorator";
import RicercaStanze from "@/components/pages/ricerca-stanze/RicercaStanze.vue";

@Component({
  components: {
    RicercaStanze,
  },
})
export default class PortineriaRicercaStanzeView extends Vue {

}
