























import { Component, Vue } from "vue-property-decorator";

@Component
export default class PortineriaTabelloneView extends Vue {
  /* DATA */
  private activeTab = "";
  private tabs = [
    {
      path: "esporta",
      icon: "mdi-table-arrow-down",
      label: "Esporta",
    },
    {
      path: "cronologia",
      icon: "mdi-table-sync",
      label: "Cronologia",
    },
  ];
}
