


































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Portineria, PortinerieCreateBody, PortinerieReplaceBody } from "operatn-api-client";

import { AlertType } from "@/store";
import ResourceManagerMixin from "@/mixins/ResourceManagerMixin";
import PortineriaHandlerMixin from "@/mixins/handlers/PortineriaHandlerMixin";

import OperatnActionDialog from "@/components/gears/dialogs/OperatnActionDialog.vue";
import OperatnBaseResourceManager, { Column, Actions } from "@/components/gears/bases/OperatnBaseResourceManager.vue";
import OperatnPortineriaForm from "@/components/gears/forms/OperatnPortineriaForm.vue";

@Component({
  components: {
    OperatnActionDialog,
    OperatnBaseResourceManager,
    OperatnPortineriaForm,
  },
})
export default class Portinerie extends Mixins<ResourceManagerMixin<Portineria, PortinerieCreateBody, PortinerieReplaceBody, number> & PortineriaHandlerMixin>(
  ResourceManagerMixin,
  PortineriaHandlerMixin
) {
  /* PROPS */

  @Prop({ type: Boolean, required: true })
  isRoot!: boolean;

  /* DATA */

  protected askDeleteText = "Sei sicuro di voler eliminare questa portineria?";
  protected askDeleteMultipleText = "Sei sicuro di voler eliminare le portinerie selezionate?";

  /* GETTERS AND SETTERS */

  get columns(): Column<Portineria>[] {
    return [
      {
        text: "ID",
        value: "id",
        groupable: false,
        editable: false,
      },
      {
        text: "Nome",
        value: "nome",
        groupable: false,

        editable: true,
        onEditCancel: () => this.sprepareUpdateBody(),
        onEditClose: () => {},
        onEditSave: () => this.updateValue(),
        onEditOpen: (item) => {
          this.prepareUpdateBody(item);
        },
        editInput: {
          type: "text",
          label: "Modifica",
          hint: "Premi invio per salvare",
          counter: true,
          rules: [this.$validator.requiredText("Portineria"), this.$validator.unique(this.portinerieValues)],
        },
      },
    ];
  }

  get portinerieValues(): string[] {
    return this.getPortinerieValues(this.values, this.backupValue);
  }

  get actions(): Actions<Portineria> {
    return {
      onEdit: (item) => this.openEdit(item),
      onDelete: (item) => this.askDelete(item),
    };
  }

  /* METHODS */

  getIdFromValue(value: Portineria): number {
    return value.id;
  }

  async deleteHandler(id: number, isMultiple: boolean): Promise<void> {
    await this.deletePortineria(id, isMultiple ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  async createHandler(value: PortinerieCreateBody): Promise<number> {
    return this.createPortineria(value);
  }

  async updateHandler(id: number, value: PortinerieReplaceBody, isTableEdit: boolean): Promise<void> {
    await this.updatePortineria(id, value, isTableEdit ? AlertType.ERRORS_QUEUE : AlertType.ERROR_ALERT);
  }

  updateBodyFromValue(value: Portineria): PortinerieReplaceBody {
    return {
      nome: value.nome,
    };
  }
  tupleValueFromCreateBody(id: number, body: PortinerieCreateBody): Portineria {
    return {
      id,
      nome: body.nome,
      dataCreazione: this.backupValue?.dataCreazione ?? new Date(),
      eliminato: null,
    };
  }
  tupleValueFromUpdateBody(id: number, body: PortinerieReplaceBody): Portineria {
    return {
      id,
      nome: body.nome,
      dataCreazione: this.backupValue?.dataCreazione ?? new Date(),
      eliminato: null,
    };
  }

  /* LIFE CYCLE */

  async mounted() {
    this.values = await this.getPortinerie();
  }
}
